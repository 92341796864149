/* datepicker.css */

.react-datepicker {
  font-family: Arial, sans-serif;
}
.input-border {
  border: 1px solid #f50157;
  border-radius: 5px;
}

.react-datepicker__header {
  background-color: #f50157; /* Set header background color */
  color: white; /* Set header text color */
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: black; /* Set day name and day number color */
}
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #f50157;
}
.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: #f50157;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #fff;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker__day--selected {
  background-color: #f50157; /* Set selected day background color */
  color: white; /* Set selected day text color */
}

.react-datepicker__day--keyboard-selected {
  background-color: #f50157; /* Set keyboard selected day background color */
  color: white; /* Set keyboard selected day text color */
}

.react-datepicker__month {
  margin: 0.4rem;
}

.react-datepicker__month-container {
  width: 240px; /* Set width of month container */
}

.react-datepicker__time-container {
  display: none; /* Hide time container */
}
